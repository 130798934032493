@font-face{
  font-family: "TCCC-UnityHeadline Bold";
  src: url("./fonts/a3fd7039bc65684a34371b2002a7711e.eot");
  src: url("./fonts/a3fd7039bc65684a34371b2002a7711e.eot?#iefix")format("embedded-opentype"),
      url("./fonts/a3fd7039bc65684a34371b2002a7711e.woff")format("woff"),
      url("./fonts/a3fd7039bc65684a34371b2002a7711e.woff2")format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}

/* Overall, set scene, background */
html {
  background-color: #c90082;
  box-shadow: inset 0 0 100px #000;
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  /* background-image: url(./images/background.png); */
  background-size: contain;
  position: absolute;
  top: 0vh;
  width: 100%;
  height: 100%;
  z-index: 10;

  width: 100%;
  height: auto;
  display: block;
  position: fixed;

  max-width: 600px;
  left: 50%;
  transform: translate(-50%, 0%);

  background: radial-gradient(transparent 75%, rgba(0,0,0,.65)), transparent;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.red-sox-red {
  color:#BD3039
}

.text--black {
  color:#000
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#attract-loop-header {
  width: 85%;
  object-fit: contain;
}

#capture-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#canvas-container {
  position: absolute;
  bottom: 0;
  left: 0;
}

#canvas-container img {
  width: 100%;
  height: auto;
}

#canvas-container.videoLeft canvas {
  left: 0;
}

#canvas-container.videoRight canvas {
  right: 0;
}

#canvas-container canvas {
  position: absolute;
  bottom: 0;
}

#capture-container video {
  width: 100%;
}

#pro-select-container {
  position: absolute;
  top: 15%;
  left: 0;
}

#idle-video {
    clip-path: inset(1px 1px);

}

/* MLB Background 
.pennant-background {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #BD3039;
}
*/

.pro-video {
  position: absolute;
  bottom: 0;
}


.demo-image-preview-fullscreen > img {
  width: 80vw;
  /* height: 100vh; */
}

@media (max-width: 768px) {
  .demo-image-preview > img {
    width: 50%;
  }
}

/* Resizing based off previews on phone. */
.App .demo-image-preview {
  height: 55vh;
  overflow: hidden;
  width: 60%;
  margin: 0 auto;
  /* -webkit-transform: scaleX(-1); */
  /* transform: scaleX(-1); */
}

.App .react-html5-camera-photo-fullscreen > video {
  object-fit: cover;
}

.MuiBox-root .selected {
  
}



.vdo__selected {
  position: absolute;
  right: 50%;
}

.MuiGrid-root.MuiGrid-item {
  position: relative;
  z-index: 1000;
}

.MuiGrid-root.MuiGrid-item .MuiBox-root {
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.MuiGrid-root.MuiGrid-item.selected .MuiBox-root {
  position: absolute;
  left: 34.5%;
  right: 0;
  top: 23%;
  background-position-x: 0%;
}

.MuiGrid-root.MuiGrid-item.selected {
  position: static;
  z-index: 0;
}

.MuiGrid-root.MuiGrid-item.selected .MuiBox-root div {
  height: 230px !important;
}

.userCapture .btn__swap-camera,
.userCapture #container-circles,
.environmentCapture .btn__swap-camera,
.environmentCapture #container-circles {
  display: none;
}

/* CAMERA CORRECTION! */

.environmentCapture #capture-container {
  transform: scale(1.5, 1) translateY(-15px);
}

.userCapture #capture-container {
  transform: scale(-1.5, 1) translateY(-15px);
}

/* Sections */
.section__absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.section__background--black {
  transition: opacity 1s;
  background-color: #000;
  height: 200%;
  width: 100%;
}


/* Prompts */
.image__prompt {
  display: block;
  width: 100%;
  position: relative;
  z-index: 9000;
}

@media (max-width: 810px) {
 .image__prompt {
    display: none;
 }
}


@media (orientation: landscape) {
  .prompt__desktop {
    display: none;
  }

  .prompt__landscape {
    display: block;
  }

  .App {
    display: none;
  }
}

@media (min-width: 811px) and (orientation: landscape) {
  .prompt__desktop {
    display: block;
  }

  .prompt__landscape {
    display: none;
  }
}

@media (orientation: portrait) && (min-width: 811px) {
  .App {
    display: none;
  }

  .prompt__desktop {
    display: block;
  }
}

@media (orientation: portrait) {
  .prompt__desktop,
  .prompt__landscape {
    display: none;
  }
}

.App__inner {
  height: 100%;
  background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, #c90082 75%, #c90082 100%);
}



.image__cover {
  width: 80%;
  margin: 0 auto;
  transition: opacity 1s;
  border-radius: 100px;
  padding-top: 60px;
}

/* Buttons */
.App .MuiButtonBase-root {
  font-family: "TCCC-UnityHeadline Bold", sans-serif;
  background-image: url(./images/ccs-button__start.png);
  background-size: contain;
  background-color: transparent;
  box-shadow: none;
  width: 245px;
  height: 245px;
  padding-left: 50px;
  padding-right: 50px;
  color: #000;
  border: none;
  border-radius: 150px;
  line-height: 22px;
  font-size: 17px;
  align-items: center;

  font-weight: normal;
  text-transform: uppercase;
  transition: opacity 1s;
}

.App .MuiButtonBase-root:active,
.App .MuiButtonBase-root:hover {
  background-color: transparent;
  box-shadow: none;
}

.App .button__ready-button {
}

.App .button__tryagain-button {
  width: 125px;
  height: 125px;
  padding-right: 50px;
  padding-left: 50px;
}

.App .button__looksgood-button {
  width: 125px;
  height: 125px;
  padding-right: 50px;
  padding-left: 50px;
}

.btn__swap-camera {
  bottom: 286px;
  position: absolute;
  z-index: 9999;
  right: 50px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.btn__swap-camera svg {
  width: 100%;
}

.btn__coupon {
  font-family: "TCCC-UnityHeadline Bold", sans-serif;
  background-color: #fff;
  padding: 15px;
  border-radius: 30px;
  text-decoration: none;
  color: #000;
}

.App #container-circles {
  bottom: 340px;
}


/* Forms */
.App .MuiInputBase-input {
  background-color: #fff;
  border-radius: 5px;
}

.App .MuiFormHelperText-root {
  color: #fff;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-family: "TCCC-UnityHeadline Bold", sans-serif;
  text-transform: uppercase;
}

.App .MuiFormLabel-root {
  background-color: #fff;
  border-radius: 5px 5px 0px 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2px;
}

.App .MuiTextField-root .MuiOutlinedInput-notchedOutline {
  top: -8px;
}

.App .MuiTextField-root .Mui-error {
  color: #000;
}

/* Text */
.text__mid-header {
  width: 70%;
  margin: 0 auto 20px;
  font-family: "TCCC-UnityHeadline Bold", sans-serif;
  color: #fff;
  font-weight: normal;
  text-transform: uppercase;
  transition: opacity 1s;
  padding-top: 0px;
}

.text__large-header {
  width: 80%;
  margin: 0 auto 30px;
  font-family: "TCCC-UnityHeadline Bold", sans-serif;
  color: #fff;
  font-size: 26px;
  font-weight: normal;
  text-transform: uppercase;
  transition: opacity 1s;
  padding-top: 60px;
}

.txt__select-header {
  font-family: "TCCC-UnityHeadline Bold", sans-serif;
  margin: 0 auto;
  position: relative;
  color: #fff;
}



/* Animations */
/*
.arroyo {
}

.verdugo {
}

.hernandez {
  top: -100px;
}

.turner {
  top: -100px;
} 

.jansen {
  top: -200px;
}

.casa {
  top: -200px;
}

.devers {
  top: -300px;
}

.duvall {
  top: -300px;
}

.arroyo .MuiBox-root {
  background-image: url(./images/0_Christian_Arroyo_Button.png);

  animation-name: PlayerSet04;
  animation-duration: .4s;
  animation-timing-function: ease-in-out;
}

.verdugo .MuiBox-root {
  background-position-x: 90%;
  background-image: url(./images/5_Alex_Verdugo_Button.png);

  animation-name: PlayerSet01;
  animation-duration: .55s;
  animation-timing-function: ease-in-out;
}

.hernandez .MuiBox-root {
  background-image: url(./images/1_Enrique_Hernandez_Button.png);

  animation-name: PlayerSet04;
  animation-duration: .65s;
  animation-timing-function: ease-in-out;
}

.turner .MuiBox-root {
  background-position-x: 90%;
  background-image: url(./images/6_Justin_Turner_Button.png);

  animation-name: PlayerSet01;
  animation-duration: .75s;
  animation-timing-function: ease-in-out;
}

.jansen .MuiBox-root {
  background-image: url(./images/2_Kenley_Jansen_Button.png);

  animation-name: PlayerSet03;
  animation-duration: .85s;
  animation-timing-function: ease-in-out;
}

.casa .MuiBox-root {
  background-position-x: 90%;
  background-image: url(./images/7_Triston_Casas_Button.png);

  animation-name: PlayerSet01;
  animation-duration: .9s;
  animation-timing-function: ease-in-out;
}

.devers .MuiBox-root {
  background-image: url(./images/3_Rafael_Devers_Button.png);

  animation-name: PlayerSet03;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.duvall .MuiBox-root {
  background-position-x: 90%;
  background-image: url(./images/4_Adam_Duvall_Button.png);

  animation-name: PlayerSet01;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes PlayerSet01 {
  from {
    transform: translateX(187%);
  }
  to { 
    transform: translateX(0);
  }
}

@keyframes PlayerSet02 {
  from {
    transform: translateX(147%);
  }
  to { 
    transform: translateX(0);
  }
}

@keyframes PlayerSet03 {
  from {
    transform: translateX(-287%);
  }
  to { 
    transform: translateX(0);
  }
}

@keyframes PlayerSet04 {
  from {
    transform: translateX(-187%);
  }
  to { 
    transform: translateX(0);
  }
}
*/